import { css } from "@emotion/react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import React from "react"
import Layout from "../../components/layout/Layout"
import Review from "../../components/Review"
import Seo from "../../components/seo"
import reviews from "../../reviews.json"

export default function Testimonials() {
  const { mobileBg, desktopBg } = useStaticQuery(
    graphql`
      query {
        mobileBg: file(relativePath: { eq: "reviews/mobile-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: CONSTRAINED
              placeholder: NONE
              width: 792
              height: 427
            )
          }
        }
        desktopBg: file(relativePath: { eq: "reviews/desktop-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: CONSTRAINED
              placeholder: NONE
              width: 1597
              height: 863
            )
          }
        }
      }
    `
  )

  let image = getImage(mobileBg)

  if (typeof window !== "undefined" && window.innerWidth >= 1024) {
    image = getImage(desktopBg)
  }

  return (
    <Layout learnMore>
      <Seo
        title="Testimonials Archive - Pikes Peak Oral Surgery & Dental Implant Center | Dental Implants and Wisdom Teeth Removals in Colorado Springs, CO"
        description="Listen to our patients and referring doctors talk about their positive experiences with our oral surgery practice in Colorado Springs, CO."
      />
      <BgImage
        image={image}
        Tag="section"
        css={css`
          text-align: center;
          background-position: center bottom;
          background-size: unset;

          @media (min-width: 880px) {
            ::after {
              background-position: right bottom !important;
            }
          }
        `}
      >
        <div className="container" style={{ padding: "128px 16px 64px" }}>
          {reviews["all"].map((review, i) => (
            <Review review={review} key={i} videoFirst={i % 2} />
          ))}
        </div>
      </BgImage>
    </Layout>
  )
}
